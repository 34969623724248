import { useEffect, useState } from "react";
import VerifyEmail from "./VerifyEmail";
import { useNavigate } from "react-router-dom";
import { authService } from "services/auth/AuthService";
import { useAlert } from "context/alert/useAlert";

export interface VerifyEmailFormState {
  email: string;
}

const VerifyEmailContainer: React.FC = () => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [uid, setUid] = useState<string | null>(null);

  const [verifyEmailFormState, setVerifyEmailFormState] =
    useState<VerifyEmailFormState>({
      email: "",
    });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uidFromUrl = urlParams.get("uid");
    if (uidFromUrl) {
      setUid(uidFromUrl);
    } else {
      showAlert("Invalid URL.", "error");
    }
  }, [showAlert]);

  const onVerifyEmail = async () => {
    if (!uid) {
      showAlert("UID is missing or invalid.", "error");
      return;
    }

    setLoading(true);
    try {
      const response = await authService.generateOtp(uid, verifyEmailFormState);
      if (response && response.status === "success") {
        showAlert("OTP sent successfully.", "success");
        navigate(
          `/auth/verify-otp?email=${encodeURIComponent(
            verifyEmailFormState.email
          )}&uid=${encodeURIComponent(uid)}`
        );
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    fieldName: keyof VerifyEmailFormState,
    value: string
  ) => {
    setVerifyEmailFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <VerifyEmail
      onSubmit={onVerifyEmail}
      onInputChange={handleInputChange}
      loading={loading}
    />
  );
};

export default VerifyEmailContainer;
