import React from "react";
import "./Register.scss";
import { useParams } from "react-router-dom";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import LabelInput from "components/inputs/labelInput/LabelInput";
import { Button } from "@progress/kendo-react-buttons";
import { InvitedUserDetail, RegisterFormState } from "./RegisterContainer";
import {
  composeValidators,
  validateConfirmPassword,
  validatePassword,
  validateRequired,
} from "utils/Validators";

interface RegisterProps {
  loading: boolean;
  userNameLoading: boolean;
  userNameValidating: boolean;
  onSubmit: () => void;
  onInputChange: (fieldName: keyof RegisterFormState, value: string) => void;
  onInputBlur: (fieldName: keyof RegisterFormState, value: string) => void;
  registerFormState: RegisterFormState;
  invitedUserDetail: InvitedUserDetail;
}

const Register: React.FC<RegisterProps> = ({
  onInputChange,
  onInputBlur,
  onSubmit,
  loading,
  userNameLoading,
  userNameValidating,
  registerFormState,
  invitedUserDetail,
}) => {
  const { userId } = useParams<{ userId: string }>();

  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "80%" }}>
          <legend className="mb-5">Complete Registration</legend>
          <Field
            id={"firstName"}
            name={"firstName"}
            label={"First Name"}
            type={"text"}
            component={LabelInput}
            value={registerFormState.firstname}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("firstname", e.target.value);
            }}
            onInputBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              onInputBlur("firstname", e.target.value);
            }}
            validator={validateRequired}
          />
          <Field
            id={"lastName"}
            name={"lastName"}
            label={"Last Name"}
            type={"text"}
            component={LabelInput}
            value={registerFormState.lastname}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("lastname", e.target.value);
            }}
            onInputBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              onInputBlur("lastname", e.target.value);
            }}
            validator={validateRequired}
          />
          <Field
            id={"username"}
            name={"username"}
            label={"Username"}
            type={"text"}
            component={LabelInput}
            loading={userNameLoading}
            loadingLabel={userNameLoading ? "Generating" : "Validating"}
            componentProps={{
              value: registerFormState.username,
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("username", e.target.value);
            }}
            onInputBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              onInputBlur("username", e.target.value);
            }}
          />
          <Field
            id={"email"}
            name={"email"}
            label={"Email"}
            type={"text"}
            component={LabelInput}
            disabled={true}
            componentProps={{
              value: invitedUserDetail.email,
            }}
          />
          <Field
            id={"phone"}
            name={"phone"}
            label={"Phone"}
            type={"text"}
            component={LabelInput}
            disabled={true}
            componentProps={{
              value: invitedUserDetail.phone,
            }}
          />
          <Field
            id={"facility"}
            name={"facility"}
            label={"Facility"}
            type={"text"}
            component={LabelInput}
            disabled={true}
            componentProps={{
              value: invitedUserDetail.facilityName,
            }}
          />
          <Field
            id={"password"}
            name={"password"}
            label={"Password"}
            type={"password"}
            component={LabelInput}
            value={registerFormState.password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("password", e.target.value);
            }}
            validator={composeValidators(validateRequired, validatePassword)}
          />
          <Field
            id={"confirmPassword"}
            name={"confirmPassword"}
            label={"Confirm Password"}
            type={"password"}
            component={LabelInput}
            value={registerFormState.confirmPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("confirmPassword", e.target.value);
            }}
            validator={composeValidators(validateRequired, (value) =>
              validateConfirmPassword(value, registerFormState.password)
            )}
          />

          <div className="k-form-buttons mt-5">
            <Button
              themeColor={"primary"}
              style={{ width: "100%" }}
              type="submit"
              disabled={loading || !formRenderProps.allowSubmit}
            >
              {loading ? "Loading..." : "Sign Up"}
            </Button>
          </div>

          <div className="resend-code-container mt-2"></div>
        </FormElement>
      )}
    ></Form>
  );
};

export default Register;
