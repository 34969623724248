const endpoints = {
  auth: {
    login: 'auth',
  },
  otp: {
    regenerateOtp: (facilityCode: string) =>
      `facilities/${facilityCode}/otp/regenerate`,
    validateOtp: (facilityCode: string) =>
      `facilities/${facilityCode}/otp/validate`,
  },
  invitedUsers: {
    generateOtp: (inviteUserUID: string) =>
      `InvitedUsers/${inviteUserUID}/GenerateOTP`,
    verifyOtp: (inviteUserUID: string) =>
      `InvitedUsers/${inviteUserUID}/VerifyOTP`,
    invitedUsersDetails: (facilityCode: string, inviteUserUID: string) =>
      `facilities/${facilityCode}/Invitedusers/${inviteUserUID}`,
  },
  users: {
    invitation: 'users/inviteuser',
    validateOrGenerateUsername: 'users/validateorgenerateusername',
    registerUser: 'users/registeruser',
    forgotPassword: (facilityCode: string) =>
      `facilities/${facilityCode}/users/forgotpassword`,
    resetPassword: (facilityCode: string, linkId: string) =>
      `facilities/${facilityCode}/users/resetpassword/${linkId}`,
    changePassword: (facilityCode: string) =>
      `facilities/${facilityCode}/users/changepassword`,
  },
  facilities: {
    facilities: 'Facilities',
    sensorsuiteConfig: (facilityCode: string) =>
      `Facilities/${facilityCode}/Sensorsuiteconfig`,
  },
  sensorSuiteProvisioning: {
    generateQRCode: (facilityId: string) =>
      `SensorSuiteprovisioning/${facilityId}`,
  },
  links: {
    getUniqueLinkInfo: (facilityCode: string, linkGuid: string) =>
      `facilities/${facilityCode}/links/${linkGuid}`,
  },
};

export default endpoints;
