export interface AuthCredentials {
  username: string;
  password: string;
  deviceId: string;
}

export interface AuthResponse {
  token: string;
  status: string;
  message?: string;
  info?: string;
}

export interface GetResponse {
  data: Array<any>;
  status: String;
  message: String;
  statusCode: string;
}

export interface GenerateOtpPayload {
  email: string;
  phone?: string;
}
export interface VerifyOtpPayload {
  email: string;
  inviteUserUId: string;
  otp: number;
}

export interface GenerateUsernamePayload {
  firstname: string;
  lastname: string;
  username?: string;
}
export interface RegisterPayload {
  firstname: string;
  lastname: string;
  inviteUserUId: string;
  username: string;
  password: string;
}

export interface ForgotPasswordPayload {
  email?: string;
  userName?: string;
  deviceId: string;
}

export enum ResourceType {
  Phone = 'Phone',
  Email = 'Email',
}

export enum UniqueLinkResourceType {
  ResetPassword = 'ResetPassword',
  UserInvitation = 'UserInvite',
}

export interface RegenerateResourceOtpPayload {
  resource_type: string;
  otpId: number;
}

export interface ValidateResourceOtpPayload {
  resource_type: string;
  username?: string;
  device_id?: string;
  otp: number;
  otpId?: number;
}

export interface ResetPasswordPayload {
  username: string;
  deviceId: string;
  password: string;
}

export interface FacilityInfo {
  Code: string;
  Id: string;
  Name: string;
}

export interface TokenPayload {
  aud: string;
  days_until_password_expires: string;
  email: string;
  exp: number;
  facilityInfo: FacilityInfo;
  first_name: string;
  iat: number;
  id: string;
  iss: string;
  last_name: string;
  nbf: number;
  permissions: any;
  sub: string;
  unique_name: string;
  username: string;
}

export interface ChangePasswordPayload {
  username: string;
  deviceId: string;
  oldpassword: string;
  newpassword: string;
}
