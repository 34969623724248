import { InternalAxiosRequestConfig } from 'axios';
import { getAuthToken } from '../../utils/TokenUtils';

export const requestInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const requestInterceptorError = (error: any) => {
  return Promise.reject(error);
};
