import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";

interface LabelDropdownListProps extends FieldRenderProps {
  data: any[];
  textField?: string;
  valueField?: string;
  id?: string;
  disabled?: boolean;
  placeholder: string;
}

const LabelDropdownList: React.FC<LabelDropdownListProps> = (
  fieldRenderProps
) => {
  const {
    validationMessage,
    visited,
    label,
    id,
    valid,
    disabled,
    data,
    textField,
    value,
    placeholder,
    onChange,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    visited && validationMessage;

  const handleChange = (event: DropDownListChangeEvent) => {
    if (onChange) {
      onChange({
        target: {
          name: fieldRenderProps.name,
          value: event.value,
        },
      });
    }
  };

  return (
    <FieldWrapper>
      <Label
        className="k-form-label"
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className="k-form-field-wrap">
        <DropDownList
          data={data}
          textField={textField}
          value={value}
          id={id}
          disabled={disabled}
          onChange={handleChange}
          valid={valid}
          fillMode={"outline"}
          defaultItem={{ [textField || "text"]: placeholder }}
          {...others}
        />
        {showValidationMessage && <Error>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default LabelDropdownList;
