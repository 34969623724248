import React, { useState } from "react";
import "./ResetPassword.scss";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useNavigate } from "react-router-dom";
import LabelInput from "components/inputs/labelInput/LabelInput";
import { ResetPasswordFormState } from "./ResetPasswordContainer";
import {
  composeValidators,
  validateConfirmPassword,
  validatePassword,
  validateRequired,
} from "utils/Validators";

interface ResetPasswordProps {
  loading: boolean;
  onSubmit: () => void;
  onInputChange: (
    fieldName: keyof ResetPasswordFormState,
    value: string
  ) => void;
  resetPasswordFormState: ResetPasswordFormState;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  loading,
  onSubmit,
  onInputChange,
  resetPasswordFormState,
}) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const signIn = () => {
    navigate("/app");
  };

  const logIn = () => {
    navigate("/");
  };

  const handlePasswordReset = async () => {};

  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "80%" }}>
          <div className="mb-5">
            <legend>Reset Password</legend>
            <p className="text-muted">
              <small>Enter a new password below to change your password.</small>
            </p>
          </div>
          <Field
            id={"password"}
            name={"password"}
            label={"Password"}
            type={"password"}
            component={LabelInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("password", e.target.value);
            }}
            validator={composeValidators(validateRequired, validatePassword)}
          />
          <Field
            id={"confirmPassword"}
            name={"confirmPassword"}
            label={"Confirm Password"}
            type={"password"}
            component={LabelInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("confirmPassword", e.target.value);
            }}
            validator={composeValidators(validateRequired, (value) =>
              validateConfirmPassword(value, resetPasswordFormState.password)
            )}
          />

          <div className="k-form-buttons mt-5">
            <Button
              themeColor={"primary"}
              style={{ width: "100%" }}
              type="submit"
              disabled={loading || !formRenderProps.allowSubmit}
            >
              {loading ? "Loading..." : "Reset password"}
            </Button>
          </div>
        </FormElement>
      )}
    ></Form>

    // <Form
    //   onSubmit={handlePasswordReset}
    //   render={(formRenderProps: FormRenderProps) => (
    //     <FormElement style={{ width: "80%" }}>
    //       <legend className="mb-5">Reset Password</legend>
    //       <Field
    //         name="password"
    //         label="Password"
    //         component={customInput}
    //         type="text"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //       />

    //       <Field
    //         name="password"
    //         label="Confirm Password"
    //         component={customInput}
    //         type="text"
    //         value={passwordConfirm}
    //         onChange={(e) => setPasswordConfirm(e.target.value)}
    //       />

    //       <div className="k-form-buttons mt-5">
    //         <Button
    //           themeColor={"primary"}
    //           style={{ width: "100%" }}
    //           onClick={signIn}
    //         >
    //           Reset password
    //         </Button>
    //       </div>
    //     </FormElement>
    //   )}
    // ></Form>
  );
};

export default ResetPassword;
