import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import "./VerifyEmail.scss";
import LabelInput from "components/inputs/labelInput/LabelInput";
import { VerifyEmailFormState } from "./VerifyEmailContainer";
import { Button } from "@progress/kendo-react-buttons";
import {
  composeValidators,
  validateEmail,
  validateRequired,
} from "utils/Validators";

interface VerifyEmailProps {
  loading: boolean;
  onSubmit: () => void;
  onInputChange: (fieldName: keyof VerifyEmailFormState, value: string) => void;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({
  loading,
  onSubmit,
  onInputChange,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "80%" }}>
          <legend className="mb-5">Enter Your Email Address</legend>

          <Field
            id={"email"}
            name={"email"}
            label={"Email"}
            placeholder={"Enter your email"}
            type={"text"}
            component={LabelInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("email", e.target.value);
            }}
            validator={composeValidators(validateRequired, validateEmail)}
          />

          <div className="k-form-buttons mt-5">
            <Button
              themeColor={"primary"}
              style={{ width: "100%" }}
              type="submit"
              disabled={loading || !formRenderProps.allowSubmit}
            >
              {loading ? "Loading..." : "Send OTP"}
            </Button>
          </div>
        </FormElement>
      )}
    ></Form>
  );
};

export default VerifyEmail;
