import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { ConfigFormState } from "./SettingsContainer";
import LabelSwitch from "components/inputs/labelSwitch/LabelSwitch";
import LabelRadioGroup from "components/inputs/labelRadioGroup/LabelRadioGroup";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Facility } from "services/facility/FacilityInterface";

interface SettingsProps {
  onGenerateQRCode: () => void;
  qrLoading: Boolean;
  configFormState: ConfigFormState;
  onInputChange: (fieldName: keyof ConfigFormState, value: string) => void;
  onSubmit: () => void;
  selectedFacility: Facility;
}

const compliances = [
  { label: "20", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
];

const turnProtocols = [
  { label: "Q2", value: "Q2" },
  { label: "Q3", value: "Q3" },
  { label: "Q4", value: "Q4" },
];

const Settings: React.FC<SettingsProps> = ({
  onInputChange,
  onGenerateQRCode,
  qrLoading,
  selectedFacility,
  configFormState,
  onSubmit,
}) => {
  return (
    <div className="mt-3">
      <h4 className="ms-5 text-secondary">Settings</h4>
      <div className="bg-white ms-5 me-5 px-5 pb-5 pt-3 mt-3">
        <Form
          render={() => (
            <FormElement>
              <div className="row">
                <Label className="k-form-label">
                  Facility Code: {selectedFacility.facilityCode}
                </Label>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <LabelRadioGroup
                    label="Compliance (degree)"
                    data={compliances}
                    layout="horizontal"
                    onChange={(e: any) => {
                      onInputChange("complianceDegree", e.value);
                    }}
                    value={configFormState.complianceDegree}
                  />
                </div>

                <div className="col-md-6">
                  <LabelRadioGroup
                    label="Turn protocol"
                    data={turnProtocols}
                    layout="horizontal"
                    onChange={(e: any) => {
                      onInputChange("turnProtocol", e.value);
                    }}
                    value={configFormState.turnProtocol}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-6">
                  <LabelSwitch
                    label="Allow Compliance override"
                    id="complianceOverride"
                    value={configFormState.enableCompliance}
                    onChange={(e: any) => {
                      onInputChange("enableCompliance", e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <LabelSwitch
                    label="Allow turn protocol override"
                    id="turnProtocolOverride"
                    value={configFormState.enableTurnProtocol}
                    onChange={(e: any) => {
                      onInputChange("enableTurnProtocol", e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <LabelSwitch
                    label="Sensorsuite Report"
                    id="sensorsuiteReport"
                    value={configFormState.enableReports}
                    onChange={(e: any) => {
                      onInputChange("enableReports", e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col">
                  <Button
                    fillMode="outline"
                    themeColor={"primary"}
                    size="small"
                    className="ms-2"
                    onClick={onGenerateQRCode}
                    style={{ minWidth: "150px", maxWidth: "250px" }}
                  >
                    {qrLoading
                      ? "Generating..."
                      : "  Generate Device Enrollment Code"}
                  </Button>
                </div>

                <div className="col text-end">
                  <Button
                    onClick={onSubmit}
                    type="submit"
                    themeColor={"primary"}
                    style={{ minWidth: "150px", maxWidth: "250px" }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </FormElement>
          )}
        ></Form>
      </div>
    </div>
  );
};

export default Settings;
