import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import { plusIcon } from "@progress/kendo-svg-icons";
import { userService } from "../../../services/user/UserService";
import { User } from "../../../models/User";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import UsersDialogContainer from "./usersDialog/UsersDialogContainer";
import { Facility } from "services/facility/FacilityInterface";

interface UsersProps {
  facilities: Array<Facility>;
}

const Users: React.FC<UsersProps> = ({ facilities }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  const [visible, setVisible] = React.useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [facility, setFacility] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [password, setPassword] = useState<string>("password");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("password");
  const [status, setStatus] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");

  const statusCell = (props: any) => {
    const isActive = props.dataItem.status;
    return <td>{isActive ? "Active" : "Inactive"}</td>;
  };

  const nameCell = (props: any) => {
    const { firstName, lastName } = props.dataItem;
    const fullName = `${firstName} ${lastName}`;
    return <td>{fullName}</td>;
  };

  const toggleDialog = () => {
    resetState();
    setVisible(!visible);
  };

  const resetState = () => {
    setEmail("");
    setPhone("");
    setFacility("");
    setRole("");
  };

  // Fetch user data on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await userService.getUsers();
        // Transform the response data
        const userData = response.items.map((user: any) => ({
          id: user.id,
          name: `${user.name}`,
          phone: user.phone,
          email: user.email,
          facility: user.facility,
          status: user.status,
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
        }));
        setUsers(userData);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSendInvitation = async () => {
    try {
      await userService.postUser({
        phone,
        email,
        facility,
        role,
        password,
        passwordConfirm,
        status,
        username,
      });
      // Optionally, re-fetch users to update the grid
      const updatedUsers = await userService.getUsers();
      setUsers(updatedUsers.items);
      toggleDialog();
      resetState();
    } catch (error) {
      console.error("Error sending invitation:", error);
    }
  };

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="ms-5 text-secondary">Users</h4>
        <Button
          className="me-5"
          themeColor={"primary"}
          type="submit"
          svgIcon={plusIcon}
          onClick={toggleDialog}
        >
          Invite
        </Button>

        {visible && (
          <UsersDialogContainer
            toggleDialog={toggleDialog}
            facilities={facilities}
          />
        )}
      </div>
      <div className="bg-white reports-container ms-5 me-5">
        <Grid
          data={users}
          pageable={{
            buttonCount: 4,
            // pageSizes: [5, 10, 15, "All"],
          }}
        >
          <GridColumn field="name" title="Name" width="200px" cell={nameCell} />
          <GridColumn field="username" title="Username" width="200px" />
          <GridColumn field="phone" title="Phone" width="150px" />
          <GridColumn field="email" title="Email" width="250px" />
          <GridColumn field="facility" title="Facility" />
          <GridColumn field="status" title="Status" cell={statusCell} />
        </Grid>
      </div>
    </div>
  );
};

export default Users;
