import React from 'react';

export interface LoaderContextProps {
  isLoading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

const LoaderContext = React.createContext<LoaderContextProps | undefined>(
  undefined
);

export default LoaderContext;
