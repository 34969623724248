import axios, { AxiosInstance } from 'axios';
import apiConfig from './ApiConfig';

const apiClient: AxiosInstance = axios.create({
  baseURL: apiConfig.sensorsuiteAuthBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;
