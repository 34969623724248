import Cookies, { CookieAttributes } from 'js-cookie';

const cookieUtils = {
  /**
   * Sets a cookie with a specified key, value, and options.
   * @param {string} key - The name of the cookie.
   * @param {string} value - The value of the cookie.
   * @param {Object} options - Additional options like expires, secure, etc.
   */
  setCookie: (
    key: string,
    value: string,
    options: CookieAttributes = {}
  ): void => {
    Cookies.set(key, value, {
      expires: 30,
      secure: true,
      sameSite: 'Lax',
      path: '/',
      ...options,
    });
  },

  /**
   * Gets the value of a cookie by its key.
   * @param {string} key - The name of the cookie to retrieve.
   * @returns {string|null} - The value of the cookie, or null if not found.
   */
  getCookie: (key: string): string | null => {
    return Cookies.get(key) || null;
  },

  /**
   * Removes a cookie by its key.
   * @param {string} key - The name of the cookie to remove.
   * @param {Object} options - Options like path, domain, etc.
   */
  removeCookie: (key: string, options: CookieAttributes = {}): void => {
    Cookies.remove(key, {
      path: '/',
      ...options,
    });
  },
};

export default cookieUtils;
