import { useState } from "react";
import { UsersFormState } from "../interfaces/UsersInterface";
import UsersDialog from "./UsersDialog";
import { userService } from "services/user/UserService";
import { useLoader } from "hooks/useLoader";
import { useAlert } from "context/alert/useAlert";
import { Facility } from "services/facility/FacilityInterface";

interface UsersDialogContainerProps {
  toggleDialog: () => void;
  facilities: Array<Facility>;
}

const UsersDialogContainer: React.FC<UsersDialogContainerProps> = ({
  toggleDialog,
  facilities,
}) => {
  const { showLoader, hideLoader } = useLoader();
  const { showAlert } = useAlert();

  const roles = [
    {
      id: "portalAdmin",
      name: "Portal Admin",
    },
    {
      id: "portalUser",
      name: "Portal User",
    },
  ];

  const [usersFormState, setUsersFormState] = useState<UsersFormState>({
    email: "",
    role: "",
    phone: "",
    facilityId: "",
  });

  const handleSendInvitation = async () => {
    showLoader();
    try {
      const response = await userService.inviteUser(usersFormState);
      if (response && response.status === "success") {
        toggleDialog();
        showAlert("Invitation sent successfully.", "success");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      hideLoader();
    }
  };

  const handleInputChange = (
    fieldName: keyof UsersFormState,
    value: string
  ) => {
    setUsersFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <UsersDialog
      onInputChange={handleInputChange}
      toggleDialog={toggleDialog}
      onSubmit={handleSendInvitation}
      facilities={facilities}
      roles={roles}
    />
  );
};

export default UsersDialogContainer;
