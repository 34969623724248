import { useEffect, useState } from "react";
import Register from "./Register";
import { useAlert } from "context/alert/useAlert";
import { authService } from "services/auth/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterPayload } from "services/auth/AuthInterface";
import { useLoader } from "hooks/useLoader";

export interface RegisterFormState {
  firstname: string;
  lastname: string;
  username: string;
  password: string;
  confirmPassword: string;
}
export interface InvitedUserDetail {
  email: string;
  facilityId: string;
  facilityName: string;
  phone: string;
  role: string;
  role_id: string;
}

const RegisterContainer: React.FC = () => {
  const { showLoader, hideLoader } = useLoader();
  const { showAlert } = useAlert();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [userNameLoading, setUsernameLoading] = useState<boolean>(false);
  const [userNameValidating, setUsernameValidating] = useState<boolean>(false);
  const [invitedUserDetail, setInvitedUserDetail] = useState<InvitedUserDetail>(
    {
      email: "",
      role: "",
      phone: "",
      facilityId: "",
      facilityName: "",
      role_id: "",
    }
  );
  const [registerFormState, setRegisterFormState] = useState<RegisterFormState>(
    {
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      confirmPassword: "",
    }
  );
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get("uid");

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!uid) {
        showAlert("UID is required to fetch user details.", "error");
        return;
      }

      try {
        showLoader();
        const response: InvitedUserDetail =
          await authService.fetchInvitedUserDetail(uid);
        if (response) {
          setInvitedUserDetail({
            email: response.email || "",
            role: response.role || "",
            phone: response.phone || "",
            facilityId: response.facilityId || "",
            facilityName: response.facilityName || "",
            role_id: response.role_id || "",
          });
        }
      } catch (error: any) {
        showAlert(error.message, "error");
      } finally {
        hideLoader();
      }
    };

    fetchUserDetails();
  }, [uid, showAlert]);

  const onRegister = async () => {
    if (!uid) {
      showAlert("UID is required to validate OTP.", "error");
      return;
    }

    const { firstname, lastname, username, password } = registerFormState;

    const registerPayload: RegisterPayload = {
      firstname,
      lastname,
      username,
      password,
      inviteUserUId: uid,
    };

    setLoading(true);
    try {
      const response = await authService.registerUser(registerPayload);
      if (response) {
        showAlert("User registered successfully.", "success");
        navigate(`/auth/login`);
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    fieldName: keyof RegisterFormState,
    value: string
  ) => {
    setRegisterFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value ?? "",
    }));
  };

  const handleInputBlur = async (
    fieldName: keyof RegisterFormState,
    value: string
  ) => {
    if (
      registerFormState.firstname &&
      registerFormState.lastname &&
      !registerFormState.username
    ) {
      setUsernameLoading(true);
      try {
        const generateUsernamePayload = {
          firstname: registerFormState.firstname,
          lastname: registerFormState.lastname,
        };
        const response = await authService.validateorGenerateUsername(
          generateUsernamePayload
        );
        if (response?.status === "success" && response.data) {
          setRegisterFormState((prevState) => ({
            ...prevState,
            username: response.data,
          }));
        } else {
          console.error(
            "Error generating username: Unexpected response format",
            response
          );
        }
      } catch (error: any) {
        showAlert(error.message, "error");
      } finally {
        setUsernameLoading(false);
      }
    }

    if (
      registerFormState.firstname &&
      registerFormState.lastname &&
      registerFormState.username
    ) {
      setUsernameValidating(true);
      try {
        const generateUsernamePayload = {
          firstname: registerFormState.firstname,
          lastname: registerFormState.lastname,
          username: registerFormState.username,
        };
        const response = await authService.validateorGenerateUsername(
          generateUsernamePayload
        );
        if (response?.status === "success" && response.data) {
        } else {
          console.error(
            "Error validating username: Unexpected response format",
            response
          );
        }
      } catch (error: any) {
        showAlert(error.message, "error");
      } finally {
        setUsernameValidating(false);
      }
    }
  };

  return (
    <Register
      onSubmit={onRegister}
      onInputChange={handleInputChange}
      onInputBlur={handleInputBlur}
      loading={loading}
      userNameLoading={userNameLoading}
      userNameValidating={userNameValidating}
      registerFormState={registerFormState}
      invitedUserDetail={invitedUserDetail}
    />
  );
};

export default RegisterContainer;
