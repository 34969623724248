import React, { useState } from "react";
import "./ForgotPassword.scss";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordFormState } from "./ForgotPasswordContainer";
import LabelInput from "components/inputs/labelInput/LabelInput";

interface ForgotPasswordProps {
  loading: boolean;
  onSubmit: () => void;
  onInputChange: (
    fieldName: keyof ForgotPasswordFormState,
    value: string
  ) => void;
  goToLogin: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  loading,
  onSubmit,
  onInputChange,
  goToLogin,
}) => {
  const validateRequired = (value: string): string | undefined =>
    value ? undefined : "This field is required.";

  return (
    <div style={{ width: "80%" }}>
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <div className="mb-5">
              <legend className="">Forgot Your Password?</legend>
              <p className="text-muted">
                <small>
                  You will receive instructions in your email for resetting your
                  password.
                </small>
              </p>
            </div>

            <Field
              id={"emailOrUsername"}
              name={"emailOrUsername"}
              label={"Email / Username"}
              placeholder={"Enter your email or username"}
              type={"text"}
              component={LabelInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onInputChange("emailOrUsername", e.target.value);
              }}
              validator={validateRequired}
            />

            <div className="k-form-buttons mt-5">
              <Button
                themeColor={"primary"}
                style={{ width: "100%" }}
                type="submit"
                disabled={loading || !formRenderProps.allowSubmit}
              >
                {loading ? "Loading..." : "Request Password Reset"}
              </Button>
            </div>
          </FormElement>
        )}
      ></Form>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={goToLogin} className="resend-code-link link-button">
          Back To Login
        </Button>
      </div>
    </div>
  );
};

export default ForgotPassword;
