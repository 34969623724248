import React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";

interface LabelRadioGroupProps extends Partial<FieldRenderProps> {
  label: string;
  data: { label: string; value: string | number }[];
  layout?: "horizontal" | "vertical";
  defaultValue?: string | number;
}

const LabelRadioGroup: React.FC<LabelRadioGroupProps> = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    label,
    data,
    layout = "horizontal",
    defaultValue,
    value,
    onChange,
    disabled,
    valid,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    visited && validationMessage ? validationMessage : null;

  return (
    <FieldWrapper>
      <Label
        className="k-form-label"
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className="k-form-field-wrap mt-1">
        <RadioGroup
          data={data}
          layout={layout}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          onChange={onChange}
          {...others}
        />
        {showValidationMessage && <Error>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default LabelRadioGroup;
