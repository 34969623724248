import { useState } from "react";
import ChangePassword from "./ChangePassword";
import { useLoader } from "hooks/useLoader";
import cookieUtils from "utils/CookieUtils";
import { authService } from "services/auth/AuthService";
import { useAlert } from "context/alert/useAlert";
import { useUser } from "hooks/useUser";
import { ChangePasswordPayload } from "services/auth/AuthInterface";

interface ChangePasswordContainerProps {
  show: boolean;
  toggleDialog: () => void;
}

export interface ChangePasswordFormState {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordContainer: React.FC<ChangePasswordContainerProps> = ({
  show,
  toggleDialog,
}) => {
  const { showLoader, hideLoader } = useLoader();
  const { showAlert } = useAlert();
  const { user } = useUser();
  const { logout } = useUser();

  const [changePasswordFormState, setChangePasswordFormState] =
    useState<ChangePasswordFormState>({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

  const mapToChangePasswordPayload = (
    changePasswordFormState: ChangePasswordFormState
  ): ChangePasswordPayload => {
    const { oldPassword, newPassword } = changePasswordFormState;
    const deviceId = cookieUtils.getCookie("deviceId") || "";

    return {
      username: user.username,
      deviceId: deviceId,
      oldpassword: changePasswordFormState.oldPassword,
      newpassword: changePasswordFormState.newPassword,
    };
  };

  const onChangePassword = async () => {
    showLoader();
    try {
      if (!user || !user.facility || !user.facility.Code) {
        throw new Error("Facility code is missing. Please try again.");
      }

      const changePasswordPayload = mapToChangePasswordPayload(
        changePasswordFormState
      );
      const response = await authService.changePassword(
        user.facility.Code,
        changePasswordPayload
      );
      if (response) {
        showAlert(
          "Password changed successfully. Please sign in again with new password.",
          "success"
        );
        logout();
        toggleDialog();
        cookieUtils.removeCookie("deviceId");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      hideLoader();
    }
  };

  const handleInputChange = (
    fieldName: keyof ChangePasswordFormState,
    value: string
  ) => {
    setChangePasswordFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value ?? "",
    }));
  };

  return show ? (
    <ChangePassword
      handleSubmit={onChangePassword}
      changePasswordFormState={changePasswordFormState}
      onInputChange={handleInputChange}
      toggleDialog={toggleDialog}
    />
  ) : null;
};

export default ChangePasswordContainer;
