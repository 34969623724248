import { useState } from "react";
import ResetPassword from "./ResetPassword";
import { useAlert } from "context/alert/useAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { authService } from "services/auth/AuthService";
import { ResetPasswordPayload } from "services/auth/AuthInterface";
import cookieUtils from "utils/CookieUtils";

export interface ResetPasswordFormState {
  password: string;
  confirmPassword: string;
}

const ResetPasswordContainer: React.FC = () => {
  const { showAlert } = useAlert();

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get("username");
  const linkGuid = queryParams.get("linkGuid");

  const [resetPasswordFormState, setResetPasswordFormState] =
    useState<ResetPasswordFormState>({
      password: "",
      confirmPassword: "",
    });

  const handleInputChange = (
    fieldName: keyof ResetPasswordFormState,
    value: string
  ) => {
    setResetPasswordFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value ?? "",
    }));
  };

  const onResetPassword = async () => {
    if (!username || !linkGuid) {
      showAlert("Username or linkId is missing.", "error");
      return;
    }
    const deviceId = cookieUtils.getCookie("deviceId") || "";
    const resetPasswordPayload: ResetPasswordPayload = {
      username: username,
      deviceId: deviceId,
      password: resetPasswordFormState.password,
    };

    setLoading(true);
    try {
      const response = await authService.resetPassword(
        linkGuid,
        resetPasswordPayload
      );
      if (response) {
        showAlert("Password reset successfully.", "success");
        cookieUtils.removeCookie("deviceId");
        navigate(`/auth/login`);
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResetPassword
      resetPasswordFormState={resetPasswordFormState}
      loading={loading}
      onInputChange={handleInputChange}
      onSubmit={onResetPassword}
    />
  );
};

export default ResetPasswordContainer;
