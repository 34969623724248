import { useEffect, useState } from "react";
import FacilitiesContext from "./FacilitiesContext";
import { Facility } from "services/facility/FacilityInterface";

export const FacilitiesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [facilities, setFacilities] = useState<string[]>([]);
  const [selectedFacility, setSelectedFacilityState] =
    useState<Facility | null>(null);

  const loadFacilities = () => {
    if (facilities.length === 0) {
      const storedFacilities = localStorage.getItem("facilities");
      if (storedFacilities) {
        const parsedFacilities = JSON.parse(storedFacilities);
        setFacilities(parsedFacilities);

        const storedSelectedFacility = localStorage.getItem("selectedFacility");
        if (storedSelectedFacility) {
          setSelectedFacilityState(JSON.parse(storedSelectedFacility));
        }
      }
    }
  };

  const setSelectedFacility = (facility: Facility) => {
    setSelectedFacilityState(facility);
    localStorage.setItem("selectedFacility", JSON.stringify(facility));
  };

  useEffect(() => {
    loadFacilities();
  }, []);

  const contextValue = {
    facilities,
    selectedFacility,
    setFacilities: (newFacilities: string[]) => {
      setFacilities(newFacilities);
      localStorage.setItem("facilities", JSON.stringify(newFacilities));
    },
    setSelectedFacility,
    loadFacilities,
  };

  return (
    <FacilitiesContext.Provider value={contextValue}>
      {children}
    </FacilitiesContext.Provider>
  );
};
