import React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Switch } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";

interface LabelSwitchProps extends Partial<FieldRenderProps> {
  id?: string;
  disabled?: boolean;
}

const LabelSwitch: React.FC<LabelSwitchProps> = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    label,
    id,
    valid,
    disabled,
    value,
    onChange,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    visited && validationMessage ? validationMessage : null;

  return (
    <FieldWrapper>
      <Label className="k-form-label">{label}</Label>
      <div className="k-form-field-wrap mt-1">
        <Switch
          id={id}
          checked={value}
          disabled={disabled}
          onChange={onChange}
          size={"small"}
          {...others}
        />
        {showValidationMessage && <Error>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default LabelSwitch;
