import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserProvider } from "./context/user/UserProvider";
import RoutesWrapper from "./routes/components/RoutesWrapper";
import { LoaderProvider } from "context/loader/Loaderprovider";
import GlobalLoader from "components/globalLoader/GlobalLoader";
import { FacilitiesProvider } from "context/facilities/FacilitiesProvider";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <LoaderProvider>
          <FacilitiesProvider>
            <GlobalLoader />
            <RoutesWrapper />
          </FacilitiesProvider>
        </LoaderProvider>
      </UserProvider>
    </div>
  );
}

export default App;
