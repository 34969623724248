export const validateRequired = (value: string): string | undefined =>
  value ? undefined : 'This field is required.';

export const validatePassword = (value: string): string | undefined => {
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,64}$/;
  if (!passwordRegex.test(value)) {
    return 'Password must contain at least one uppercase letter, one special character, one digit, and be 8-64 characters long.';
  }
  return undefined;
};

export const validateEmail = (value: string): string | undefined => {
  const emailRegex =
    /^(?=.{1,64}@.{1,255}$)(?=.{6,256}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}$/;
  return emailRegex.test(value) ? undefined : 'Please enter a valid email.';
};

export const validateConfirmPassword = (
  value: string,
  password: string
): string | undefined =>
  value === password ? undefined : 'Passwords do not match.';

export const composeValidators =
  (...validators: ((value: string) => string | undefined)[]) =>
  (value: string): string | undefined => {
    for (const validator of validators) {
      const error = validator(value);
      if (error) {
        return error;
      }
    }
    return undefined;
  };
