import { useEffect, useState } from "react";
import VerifyOtp from "./VerifyOtp";
import { useLocation, useNavigate } from "react-router-dom";
import { authService } from "services/auth/AuthService";
import { useAlert } from "context/alert/useAlert";
import { FormRenderProps } from "@progress/kendo-react-form";
import { useLoader } from "hooks/useLoader";
import { Button } from "@progress/kendo-react-buttons";

export interface VerifyOtpFormState {
  email: string;
  inviteUserUId: string;
  otp: number;
}

const VerifyOtpContainer: React.FC = () => {
  const { showLoader, hideLoader } = useLoader();
  const { showAlert } = useAlert();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const uid = queryParams.get("uid");

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [verifyOtpFormState, setVerifyOtpFormState] =
    useState<VerifyOtpFormState>({
      otp: 0,
      inviteUserUId: "",
      email: "",
    });

  useEffect(() => {
    if (email && uid) {
      setVerifyOtpFormState((prevState) => ({
        ...prevState,
        email: email,
        inviteUserUId: uid,
      }));
    }
  }, [email, uid]);

  const onSubmit = async () => {
    if (!uid) {
      showAlert("UID is required to validate OTP.", "error");
      return;
    }

    setLoading(true);
    try {
      const response = await authService.verifyOtp(uid, verifyOtpFormState);
      if (response) {
        showAlert("OTP verified successfully.", "success");
        navigate(`/auth/register?uid=${encodeURIComponent(uid)}`);
        // navigate("/auth/register/23432");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    if (!uid) {
      showAlert("UID is missing or invalid.", "error");
      return;
    }

    if (!email) {
      showAlert("Email is missing or invalid.", "error");
      return;
    }

    showLoader();
    try {
      const response = await authService.generateOtp(uid, { email: email });
      if (response && response.status === "success") {
        showAlert("OTP sent successfully.", "success");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      hideLoader();
    }
  };

  const handleInputChange = (
    fieldName: keyof VerifyOtpFormState,
    value: string
  ) => {
    setVerifyOtpFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <VerifyOtp
      loading={loading}
      onSubmit={onSubmit}
      onInputChange={handleInputChange}
      resendCode={resendCode}
    />
  );
};

export default VerifyOtpContainer;
