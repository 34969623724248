import { useAlert } from "context/alert/useAlert";
import { useLoader } from "hooks/useLoader";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UniqueLinkResourceType } from "services/auth/AuthInterface";
import { authService } from "services/auth/AuthService";

interface UniqueLinkInfoResponse {
  is_active: boolean;
  link_guid: string;
  resource_type: UniqueLinkResourceType;
  username: string;
}

const UniqueLinkHandler: React.FC = () => {
  const { facilityCode, linkGuid } = useParams<{
    facilityCode: string;
    linkGuid: string;
  }>();
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [linkExpired, setLinkExpired] = useState<Boolean>(false);

  const goToLogin = () => {
    navigate("/auth/login");
  };

  useEffect(() => {
    console.log("Current unique URL:", window.location.href);
  }, []);

  useEffect(() => {
    const fetchUniqueLinkInfo = async () => {
      if (!linkGuid || !facilityCode) {
        showAlert(
          "Missing required route parameters: linkId or facilityId.",
          "error"
        );
        return;
      }

      showLoader();
      try {
        const uniqueLinkInfo: UniqueLinkInfoResponse =
          await authService.getUniqueLinkInfo(facilityCode, linkGuid);
        if (uniqueLinkInfo) {
          if (uniqueLinkInfo.is_active) {
            if (
              uniqueLinkInfo.resource_type ===
              UniqueLinkResourceType.ResetPassword
            ) {
              navigate(
                `/auth/reset-password?username=${encodeURIComponent(
                  uniqueLinkInfo.username
                )}&linkGuid=${encodeURIComponent(linkGuid)}`
              );
            } else {
              showAlert("Unknown resource type", "error");
            }
          } else {
            setLinkExpired(true);
          }
        }
      } catch (error: any) {
        showAlert(error.message, "error");
      } finally {
        hideLoader();
      }
    };

    fetchUniqueLinkInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkGuid, facilityCode, navigate]);

  return (
    <>
      {linkExpired && (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="card p-4 shadow w-50 mx-auto">
            <div className="text-center">
              <h4 className="mt-3">Link Expired</h4>
              <p className="text-muted">
                To reset your password, return to the sign-in page and select
                "Forgot Password" to send a new email.
              </p>
              <button className="btn btn-primary mt-3" onClick={goToLogin}>
                Return to Sign In
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UniqueLinkHandler;
