import { Loader } from "@progress/kendo-react-indicators";
import "./GlobalLoader.scss";
import { useLoader } from "hooks/useLoader";

const GlobalLoader: React.FC = () => {
  const { isLoading } = useLoader();

  if (!isLoading) return null;

  return (
    <div className="global-loader">
      <Loader type="infinite-spinner" />
    </div>
  );
};

export default GlobalLoader;
