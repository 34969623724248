import apiClient from '../../api/ApiClient';
import endpoints from '../../api/Endpoints';
import {
  AuthCredentials,
  AuthResponse,
  ChangePasswordPayload,
  ForgotPasswordPayload,
  GenerateOtpPayload,
  GenerateUsernamePayload,
  RegenerateResourceOtpPayload,
  RegisterPayload,
  ResetPasswordPayload,
  ValidateResourceOtpPayload,
  VerifyOtpPayload,
} from './AuthInterface';

export const authService = {
  login: async (credentials: AuthCredentials): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.auth.login,
        credentials
      );
      return response;
    } catch (error: any) {
      if (error.statusCode === '302') {
        return error;
      } else {
        throw new Error(error.message || 'Invalid username or password');
      }
    }
  },

  generateOtp: async (
    inviteUserUID: string,
    generateOtpPayload: GenerateOtpPayload
  ): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.invitedUsers.generateOtp(inviteUserUID),
        generateOtpPayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  verifyOtp: async (
    inviteUserUID: string,
    verifyOtpPayload: VerifyOtpPayload
  ): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.invitedUsers.verifyOtp(inviteUserUID),
        verifyOtpPayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  validateorGenerateUsername: async (
    generateUsernamePayload: GenerateUsernamePayload
  ): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.users.validateOrGenerateUsername,
        generateUsernamePayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  fetchInvitedUserDetail: async (inviteUserUID: string): Promise<any> => {
    try {
      const response = await apiClient.get<AuthResponse>(
        endpoints.invitedUsers.invitedUsersDetails('HQ', inviteUserUID)
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.Message || 'Something went wrong');
    }
  },

  registerUser: async (registerPayload: RegisterPayload): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.users.registerUser,
        registerPayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  forgotPassword: async (
    forgotPasswordPayload: ForgotPasswordPayload
  ): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.users.forgotPassword('HQ'),
        forgotPasswordPayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  regenerateResourceOtp: async (
    regenerateResourceOtpPayload: RegenerateResourceOtpPayload
  ): Promise<any> => {
    try {
      const response = await apiClient.post<any>(
        endpoints.otp.regenerateOtp('HQ'),
        regenerateResourceOtpPayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  validateResourceOtp: async (
    validateResourceOtpPayload: ValidateResourceOtpPayload
  ): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.otp.validateOtp('HQ'),
        validateResourceOtpPayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  getUniqueLinkInfo: async (
    facilityCode: string,
    linkGuid: string
  ): Promise<any> => {
    try {
      const response = await apiClient.get<AuthResponse>(
        endpoints.links.getUniqueLinkInfo(facilityCode, linkGuid)
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  resetPassword: async (
    linkId: string,
    resetPasswordPayload: ResetPasswordPayload
  ): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.users.resetPassword('HQ', linkId),
        resetPasswordPayload
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  changePassword: async (
    facilityCode: string,
    changePasswordPayload: ChangePasswordPayload
  ): Promise<any> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.users.changePassword(facilityCode),
        changePasswordPayload
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },
};
