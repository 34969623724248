import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import LabelInput from "components/inputs/labelInput/LabelInput";
import { UsersFormState } from "../interfaces/UsersInterface";
import LabelDropdownList from "components/inputs/labelDropdownList/LabelDropdownList";
import "./UsersDialog.scss";
import LabelMaskedInput from "components/inputs/labelMaskedInput/LabelMaskedInput";
import { Facility } from "services/facility/FacilityInterface";
import {
  composeValidators,
  validateEmail,
  validateRequired,
} from "utils/Validators";

interface Role {
  id: string;
  name: string;
}
interface UsersDialogProps {
  onInputChange: (fieldName: keyof UsersFormState, value: string) => void;
  toggleDialog: () => void;
  onSubmit: () => void;
  facilities: Array<Facility>;
  roles: Array<Role>;
}

const UsersDialog: React.FC<UsersDialogProps> = ({
  onInputChange,
  toggleDialog,
  onSubmit,
  facilities,
  roles,
}) => {
  return (
    <Dialog
      title={"User Invitation"}
      onClose={toggleDialog}
      width={800}
      height={400}
      autoFocus={false}
      autoFocusedElement="#sendInvitation"
    >
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Field
                  id={"email"}
                  name={"email"}
                  label="Email"
                  type={"text"}
                  placeholder="Email"
                  component={LabelInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onInputChange("email", e.target.value);
                  }}
                  validator={composeValidators(validateRequired, validateEmail)}
                />
              </div>

              <div className="col-md-6 mb-3">
                <Field
                  id={"phone"}
                  name={"phone"}
                  label="Phone"
                  type={"text"}
                  component={LabelMaskedInput}
                  placeholder="(###)###-####"
                  mask="(999)000-0000"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onInputChange("phone", e.target.value);
                  }}
                  validator={validateRequired}
                  showError={formRenderProps.touched && !formRenderProps.valid}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Field
                  id={"facility"}
                  name={"facility"}
                  label="Facility"
                  component={LabelDropdownList}
                  data={facilities}
                  textField="facilityName"
                  placeholder="Select Facility"
                  onChange={(e: any) => {
                    const facilityValue: string = e.target.value.facilityId;
                    onInputChange("facilityId", facilityValue);
                  }}
                  validator={validateRequired}
                />
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  id={"role"}
                  name={"role"}
                  label="Role"
                  component={LabelDropdownList}
                  data={roles}
                  textField="name"
                  placeholder="Select Role"
                  onChange={(e: any) => {
                    const roleValue: string = e.target.value.id;
                    onInputChange("role", roleValue);
                  }}
                  validator={validateRequired}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <Button type="submit" id="sendInvitation" themeColor={"primary"}>
                Send Invitation
              </Button>
            </div>
          </FormElement>
        )}
      ></Form>
    </Dialog>
  );
};

export default UsersDialog;
