import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useFacilities } from "hooks/useFacilities";
import { useEffect, memo } from "react";

const FacilitySelect: React.FC = () => {
  const { facilities, selectedFacility, setSelectedFacility, loadFacilities } =
    useFacilities();

  useEffect(() => {
    loadFacilities();
  }, [loadFacilities]);

  const handleFacilityChange = (event: any) => {
    setSelectedFacility(event.value);
  };

  return (
    <>
      <DropDownList
        size={"small"}
        fillMode={"flat"}
        rounded={"medium"}
        data={facilities}
        textField="facilityName"
        dataItemKey="facilityId"
        onChange={handleFacilityChange}
        value={selectedFacility}
        style={{
          width: "300px",
        }}
      />
    </>
  );
};

export default memo(FacilitySelect);
