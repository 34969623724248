import { useLoader } from "hooks/useLoader";
import Users from "pages/app/users/Users";
import { useEffect, useState } from "react";
import { GetResponse } from "services/auth/AuthInterface";
import { Facility } from "services/facility/FacilityInterface";
import { facilityService } from "services/facility/FacilityService";

const UsersContainer: React.FC = () => {
  const { showLoader, hideLoader } = useLoader();
  const [facilities, setFacilities] = useState<Facility[]>([]);

  useEffect(() => {
    const fetchFacilities = async () => {
      showLoader();
      try {
        const response: any = await facilityService.getFacilities();
        setFacilities(response);
      } catch (errror: any) {
      } finally {
        hideLoader();
      }
    };

    fetchFacilities();
  }, []);

  return <Users facilities={facilities} />;
};

export default UsersContainer;
