import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import LabelInput from "components/inputs/labelInput/LabelInput";
import { ChangePasswordFormState } from "./ChangePasswordContainer";
import { Button } from "@progress/kendo-react-buttons";
import {
  composeValidators,
  validateConfirmPassword,
  validatePassword,
  validateRequired,
} from "utils/Validators";

interface ChangePasswordProps {
  handleSubmit: () => void;
  changePasswordFormState: ChangePasswordFormState;
  toggleDialog: () => void;
  onInputChange: (
    fieldName: keyof ChangePasswordFormState,
    value: string
  ) => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  handleSubmit,
  changePasswordFormState,
  onInputChange,
  toggleDialog,
}) => {
  return (
    <>
      <Dialog title={"Change Password"} onClose={toggleDialog} width={600}>
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <Field
                id={"oldPassword"}
                name={"oldPassword"}
                label={"Old Password"}
                type={"password"}
                component={LabelInput}
                value={changePasswordFormState.oldPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onInputChange("oldPassword", e.target.value);
                }}
                validator={composeValidators(
                  validateRequired,
                  validatePassword
                )}
              />
              <Field
                id={"newPassword"}
                name={"newPassword"}
                label={"New Password"}
                type={"password"}
                component={LabelInput}
                value={changePasswordFormState.newPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onInputChange("newPassword", e.target.value);
                }}
                validator={composeValidators(
                  validateRequired,
                  validatePassword
                )}
              />
              <Field
                id={"confirmPassword"}
                name={"confirmPassword"}
                label={"Confirm Password"}
                type={"password"}
                component={LabelInput}
                value={changePasswordFormState.newPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onInputChange("confirmPassword", e.target.value);
                }}
                validator={composeValidators(validateRequired, (value) =>
                  validateConfirmPassword(
                    value,
                    changePasswordFormState.newPassword
                  )
                )}
              />

              <div className="d-flex justify-content-end mt-5 mb-3">
                <Button
                  type="submit"
                  id="updatePassword"
                  themeColor={"primary"}
                >
                  Update Password
                </Button>
              </div>
            </FormElement>
          )}
        ></Form>
      </Dialog>
    </>
  );
};

export default ChangePassword;
