import { useEffect, useState } from "react";
import Login from "./Login";
import {
  LoginFormState,
  TwoFactorFormState,
} from "./interfaces/LoginInterface";
import {
  AuthCredentials,
  TokenPayload,
} from "../../../services/auth/AuthInterface";
import { authService } from "../../../services/auth/AuthService";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { IUser } from "../../../routes";
import { PRIVILEGES_MOCKS } from "../../../mocks/privileges";
import { useAlert } from "../../../context/alert/useAlert";
import { facilityService } from "services/facility/FacilityService";
import { useFacilities } from "hooks/useFacilities";
import TwoFactor from "../two-factor/TwoFactor";
import { v4 as uuidv4 } from "uuid";
import cookieUtils from "utils/CookieUtils";
import { decodeToken } from "react-jwt";

const LoginContainer: React.FC = () => {
  const navigate = useNavigate();
  const { saveUser } = useUser();
  const { showAlert } = useAlert();
  const { setSelectedFacility } = useFacilities();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loginFormState, setLoginFormState] = useState<LoginFormState>({
    username: "",
    password: "",
  });
  const [verifyOtpFormState, setVerifyOtpFormState] =
    useState<TwoFactorFormState>({
      otp: 0,
    });
  const [loading, setLoading] = useState<boolean>(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [resourceType, setResourceType] = useState<string>("");
  const [otpId, setOtpId] = useState<number>(0);

  useEffect(() => {
    manageDeviceId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (
    fieldName: keyof LoginFormState,
    value: string
  ) => {
    setLoginFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const mapToAuthCredentials = (
    loginFormState: LoginFormState
  ): AuthCredentials => {
    const deviceId = cookieUtils.getCookie("deviceId") || "";
    return {
      username: loginFormState.username,
      password: loginFormState.password,
      deviceId: deviceId,
    };
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const credentials = mapToAuthCredentials(loginFormState);
      const response = await authService.login(credentials);

      if (response.statusCode && response.statusCode === "302") {
        setResourceType(response.info.deviceVerification.resource_type);
        setOtpId(response.info.deviceVerification.otp_id);
        showAlert("OTP sent successfully to registered email", "success");
        setTwoFactorAuth(true);
      }

      if (response.statusCode && response.statusCode === "200") {
        const { token } = response;
        setToken(token);
        const decodedToken = decodeToken<TokenPayload>(token);
        if (!decodedToken) {
          throw new Error("Invalid Username or Password");
        }

        localStorage.setItem("token", token);
        fetchFacilities();
        manageDeviceId();
        const user: IUser = {
          username: decodedToken.username,
          firstName: decodedToken.first_name,
          lastName: decodedToken.last_name,
          email: decodedToken.email,
          isAuthenticated: true,
          privileges: PRIVILEGES_MOCKS.create,
          facility: decodedToken.facilityInfo,
        };
        saveUser(user);
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const regenerateOtp = async () => {
    try {
      const payload = {
        resource_type: resourceType,
        otpId: otpId,
      };
      const otpResponse = await authService.regenerateResourceOtp(payload);
      if (otpResponse) {
        showAlert("OTP sent successfully to registered email", "success");
      }
    } catch (error: any) {
      showAlert(error, "error");
    }
  };

  const fetchFacilities = async () => {
    const facilities = await facilityService.getFacilities();
    if (facilities.length > 0) {
      const sortedFacilities = [...facilities].sort((a, b) =>
        a.facilityName.localeCompare(b.facilityName)
      );
      localStorage.setItem("facilities", JSON.stringify(sortedFacilities));
      setSelectedFacility(sortedFacilities[0]);
      localStorage.setItem(
        "selectedFacility",
        JSON.stringify(sortedFacilities[0])
      );
    }
  };

  const onVerifyOtp = async () => {
    const deviceId = cookieUtils.getCookie("deviceId") || "";
    const validateResourceOtpPayload = {
      resource_type: resourceType,
      username: loginFormState.username,
      device_id: deviceId,
      otp: verifyOtpFormState.otp,
    };
    setLoading(true);
    try {
      const response = await authService.validateResourceOtp(
        validateResourceOtpPayload
      );

      if (response) {
        // Again call auth API internally with valid deviceDI
        handleLogin();
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleTwoFactorInputChange = (
    fieldName: keyof TwoFactorFormState,
    value: string
  ) => {
    setVerifyOtpFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const generateDeviceId = () => {
    return uuidv4();
  };

  const manageDeviceId = () => {
    const existingDeviceId = cookieUtils.getCookie("deviceId");
    if (!existingDeviceId) {
      const deviceId = generateDeviceId();
      cookieUtils.setCookie("deviceId", deviceId);
    }
  };

  return (
    <>
      {!twoFactorAuth && (
        <Login
          loginFormState={loginFormState}
          onInputChange={handleInputChange}
          onSubmit={handleLogin}
          errorMessage={errorMessage}
          loading={loading}
        />
      )}

      {twoFactorAuth && (
        <TwoFactor
          loading={loading}
          onSubmit={onVerifyOtp}
          onInputChange={handleTwoFactorInputChange}
          resendCode={regenerateOtp}
        />
      )}
    </>
  );
};

export default LoginContainer;
