import apiClient from 'api/ApiClient';
import endpoints from 'api/Endpoints';
import { GetResponse } from 'services/auth/AuthInterface';

export const facilityService = {
  getFacilities: async (): Promise<any> => {
    try {
      const response = await apiClient.get<GetResponse>(
        endpoints.facilities.facilities
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },
};
