import React, { useState } from "react";
import "./Login.scss";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import LabelInput from "../../../components/inputs/labelInput/LabelInput";
import { LoginProps } from "./interfaces/LoginInterface";
import { useNavigate } from "react-router-dom";
import { validateRequired } from "utils/Validators";

const Login: React.FC<LoginProps> = ({ onInputChange, onSubmit, loading }) => {
  const navigate = useNavigate();

  const forgotPassword = () => {
    navigate("/auth/forgot-password");
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "80%" }}>
          <legend className="mb-5">Sign In</legend>

          <Field
            id={"username"}
            name={"username"}
            label={"Username"}
            type={"text"}
            component={LabelInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("username", e.target.value);
            }}
            validator={validateRequired}
          />
          <Field
            id={"password"}
            name={"password"}
            label={"Password"}
            type={"password"}
            component={LabelInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange("password", e.target.value);
            }}
            validator={validateRequired}
          />

          <div className="k-form-buttons mt-5">
            <Button
              themeColor={"primary"}
              style={{ width: "100%" }}
              type="submit"
              disabled={loading || !formRenderProps.allowSubmit}
            >
              {loading ? "Loading..." : "Sign In"}
            </Button>
          </div>

          <div className="resend-code-container mt-2">
            <Button
              onClick={forgotPassword}
              className="resend-code-link link-button"
            >
              Forgot Password?
            </Button>
          </div>
        </FormElement>
      )}
    ></Form>
  );
};

export default Login;

// const originalData = {
//   permissions: [
//     {
//       feature_name: "USER_MANAGEMENT",
//       can_create: true,
//       can_read: true,
//       can_update: true,
//       can_delete: true,
//     },
//     {
//       feature_name: "CONTENT_MANAGEMENT",
//       can_create: true,
//       can_read: true,
//       can_update: true,
//       can_delete: true,
//     }
//   ]
// };

// const actionMap = {
//   can_create: "create",
//   can_read: "read",
//   can_update: "update",
//   can_delete: "delete"
// };

// const convertedData = {
//   permissions: originalData.permissions.reduce((acc, permission) => {
//     const { feature_name, ...actions } = permission;
//     acc[feature_name] = Object.keys(actions)
//       .filter(key => actions[key])
//       .map(action => actionMap[action]);
//     return acc;
//   }, {})
// };

// console.log(convertedData);

// {
//   "permissions": {
//     "USER_MANAGEMENT": [
//       "create",
//       "can_read",
//       "can_update",
//       "can_delete"
//     ],
//     "CONTENT_MANAGEMENT": [
//       "create",
//       "read",
//       "update",
//       "delete"
//     ]
//   }
// }

//   onSubmit={handleLogin}
//   render={(formRenderProps: FormRenderProps) => (
//     <FormElement style={{ width: "80%" }}>
//       <legend className="mb-5">Sign In</legend>
//       <Field
//         name={"identity"}
//         label={"Username"}
//         component={usernameInput}
//         type={"text"}
//       />
//       <Field
//         name={"password"}
//         label={"Password"}
//         component={usernameInput}
//         type={"password"}
//       />
//       {error && <p className="text-danger mt-2">{error}</p>}{" "}
//       <div className="k-form-buttons mt-5">
//         <Button
//           themeColor={"primary"}
//           style={{ width: "100%" }}
//           type="submit"
//         >
//           Sign In
//         </Button>
//       </div>
//       <div className="resend-code-container mt-2">
//         <a
//           href="#"
//           onClick={forgotPassword}
//           className="resend-code-link"
//           style={{ textDecoration: "none" }}
//         >
//           Forgot Password?
//         </a>
//       </div>
//     </FormElement>
//   )}
// ></Form>

// const signIn = () => {
//   navigate("/auth/two-factor");
// };

// const forgotPassword = () => {
//   navigate("/auth/forgot-password");
// };

// const handleLogin = async (formData: any) => {
//   const { identity, password } = formData;
//   try {
//     const response = await authService.login({ identity, password });
//     console.log("Authentication successful:", response);
//     const { token, record } = response;

//     localStorage.setItem("token", token);
//     localStorage.setItem("user", JSON.stringify(record));

//     setSuccessVisible(true);
//     setForPrivilege();
//     navigate("/auth/two-factor");
//   } catch (error) {
//     setError("Invalid username or password.");
//     console.error(error);
//   }
// };

// const setForPrivilege = () => {
//   const user: IUser = {
//     username: "",
//     isAuthenticated: true,
//     privileges: PRIVILEGES_MOCKS.create,
//   };
//   saveUser(user);
// };

// const [identity, setIdentity] = React.useState<string>("");
// const [password, setPassword] = React.useState<string>("");
// const [error, setError] = React.useState<string | null>(null);
// const [successVisible, setSuccessVisible] = React.useState<boolean>(false);

// const navigate = useNavigate();
// const { saveUser } = useUser();
