import apiClient from 'api/ApiClient';
import apiConfig from 'api/ApiConfig';
import endpoints from 'api/Endpoints';

export interface SensorsuiteConfigPayload {
  enableReports: boolean;
  enableCompliance: boolean;
  enableTurnProtocol: boolean;
  complianceDegree: number;
  turnProtocol: string;
}

export const settingsService = {
  generateQRCode: async (facilityId: string): Promise<any> => {
    try {
      const response = await apiClient.get<any>(
        endpoints.sensorSuiteProvisioning.generateQRCode(facilityId),
        {
          baseURL: apiConfig.sensorsuiteApiBaseURL,
          responseType: 'blob',
        }
      );

      return response;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  fetchSensorsuiteConfig: async (facilityCode: string): Promise<any> => {
    try {
      const response = await apiClient.get<any>(
        endpoints.facilities.sensorsuiteConfig(facilityCode)
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },

  udpateSensorsuiteConfig: async (
    facilityCode: string,
    sensorsuiteConfigPayload: SensorsuiteConfigPayload
  ): Promise<any> => {
    try {
      const response = await apiClient.patch<any>(
        endpoints.facilities.sensorsuiteConfig(facilityCode),
        sensorsuiteConfigPayload
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong');
    }
  },
};
