import FacilitiesContext from 'context/facilities/FacilitiesContext';
import { useContext } from 'react';

export const useFacilities = () => {
  const context = useContext(FacilitiesContext);
  if (!context) {
    throw new Error('useFacilities must be used within a FacilitiesProvider');
  }
  return context;
};
