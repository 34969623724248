import { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordPayload } from "services/auth/AuthInterface";
import { authService } from "services/auth/AuthService";
import { useAlert } from "context/alert/useAlert";
import cookieUtils from "utils/CookieUtils";

export interface ForgotPasswordFormState {
  emailOrUsername?: string;
}

const ForgotPassWordContainer: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [forgotPasswordFormState, setForgotPasswordFormState] =
    useState<ForgotPasswordFormState>({
      emailOrUsername: "",
    });
  const { showAlert } = useAlert();

  const mapToForgotPasswordPayload = (
    forgotPasswordFormState: ForgotPasswordFormState
  ): ForgotPasswordPayload => {
    const { emailOrUsername } = forgotPasswordFormState;

    if (!emailOrUsername?.trim()) {
      throw new Error("Email or username is required");
    }

    const trimmedInput = emailOrUsername.trim();
    const deviceId = cookieUtils.getCookie("deviceId") || "";
    const payloadKey = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedInput)
      ? "email"
      : "userName";

    return {
      [payloadKey]: trimmedInput,
      deviceId,
    };
  };

  // const mapToForgotPasswordPayload = (
  //   forgotPasswordFormState: ForgotPasswordFormState
  // ): ForgotPasswordPayload => {
  //   const emailOrUsername = forgotPasswordFormState.emailOrUsername?.trim();

  //   if (!emailOrUsername) {
  //     throw new Error("Email or username is required");
  //   }

  //   const deviceId = cookieUtils.getCookie("deviceId") || "";
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   return emailRegex.test(emailOrUsername)
  //     ? { email: emailOrUsername, deviceId: deviceId }
  //     : { userName: emailOrUsername, deviceId: deviceId };
  // };

  const onRequestPasswordReset = async () => {
    setLoading(true);
    try {
      const forgotPasswordPayload = mapToForgotPasswordPayload(
        forgotPasswordFormState
      );
      const response = await authService.forgotPassword(forgotPasswordPayload);
      if (response) {
        showAlert("Reset instructions sent to email.", "success");
        navigate(`/auth/login`);
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const navigateToLogin = () => {
    navigate(`/auth/login`);
  };

  const handleInputChange = (
    fieldName: keyof ForgotPasswordFormState,
    value: string
  ) => {
    setForgotPasswordFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <ForgotPassword
      onSubmit={onRequestPasswordReset}
      onInputChange={handleInputChange}
      loading={loading}
      goToLogin={navigateToLogin}
    />
  );
};

export default ForgotPassWordContainer;
