import { Button } from "@progress/kendo-react-buttons";
import { VerifyOtpFormState } from "./VerifyOtpContainer";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import LabelInput from "components/inputs/labelInput/LabelInput";
import React from "react";
import { validateRequired } from "utils/Validators";

interface VerifyOtpProps {
  loading: boolean;
  onSubmit: () => void;
  onInputChange: (fieldName: keyof VerifyOtpFormState, value: string) => void;
  resendCode: () => void;
}

const VerifyOtp: React.FC<VerifyOtpProps> = ({
  loading,
  onSubmit,
  onInputChange,
  resendCode,
}) => {
  const formRef = React.useRef(null);
  return (
    <div style={{ width: "80%" }}>
      <Form
        ref={formRef}
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <div className="mb-5">
              <legend className="">Verify Your Email Address</legend>
              <p className="text-muted">
                <small>
                  Enter the 6-digit code that was sent to your email.
                </small>
              </p>
            </div>

            <Field
              id={"otp"}
              name={"otp"}
              label={"OTP"}
              placeholder={"Enter code"}
              type={"text"}
              maxLength={6}
              component={LabelInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onInputChange("otp", e.target.value);
              }}
              validator={validateRequired}
            />

            <div className="k-form-buttons mt-5">
              <Button
                themeColor={"primary"}
                style={{ width: "100%" }}
                type="submit"
                disabled={loading || !formRenderProps.allowSubmit}
              >
                {loading ? "Loading..." : "Verify Email"}
              </Button>
            </div>
          </FormElement>
        )}
      ></Form>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={resendCode} className="resend-code-link link-button">
          Resend code
        </Button>
      </div>
    </div>
  );
};

export default VerifyOtp;
