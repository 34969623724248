import { FC } from 'react';
import Reports from '../pages/app/reports/Reports';
import Umm from '../pages/app/umm/Umm';
import Page404 from '../pages/error/Page404';
import Dashboard from '../pages/app/dashboard/Dashboard';
import ResetPassword from '../pages/auth/reset-password/ResetPassword';
import LoginContainer from '../pages/auth/login/LoginContainer';
import AuthenticationLayout from '../components/authenticationLayout/AuthenticationLayout';
import UsersContainer from '../pages/app/users/UsersContainer';
import VerifyEmailContainer from 'pages/auth/verify-email/VerifyEmailContainer';
import VerifyOtpContainer from 'pages/auth/verify-otp/VerifyOtpContainer';
import RegisterContainer from 'pages/auth/register/RegisterContainer';
import SettingsContainer from 'pages/app/settings/SettingsContainer';
import ForgotPasswordContainer from 'pages/auth/forgot-password/ForgotPasswordContainer';
import UniqueLinkHandler from 'pages/auth/unique-link-handler/UniqueLinkHandler';
import Layout from 'shared/layout/Layout';
import ResetPasswordContainer from 'pages/auth/reset-password/ResetPasswordContainer';

export interface IRoute {
  id: string;
  exact?: boolean;
  path: string;
  component?: FC;
  isProtected?: boolean;
  children?: IRoute[];
  redirect?: string;
}

export const appRoutes: IRoute[] = [
  {
    id: 'auth',
    path: '/auth',
    component: AuthenticationLayout,
    isProtected: false,
    children: [
      {
        id: 'login',
        exact: true,
        path: 'login',
        component: LoginContainer,
        isProtected: false,
      },
      {
        id: 'register',
        path: 'register',
        component: RegisterContainer,
        exact: true,
        isProtected: false,
      },
      {
        id: 'forgotPassword',
        path: 'forgot-password',
        component: ForgotPasswordContainer,
        exact: true,
        isProtected: false,
      },
      {
        id: 'resetPassword',
        path: 'reset-password',
        component: ResetPasswordContainer,
        exact: true,
        isProtected: false,
      },
      {
        id: 'verifyEmail',
        path: 'verify-email',
        component: VerifyEmailContainer,
        exact: true,
        isProtected: false,
      },
      {
        id: 'verifyOtp',
        path: 'verify-otp',
        component: VerifyOtpContainer,
        exact: true,
        isProtected: false,
      },
    ],
  },
  {
    id: 'app',
    path: '/app',
    component: Layout,
    isProtected: true,
    children: [
      {
        id: 'dashboard',
        path: 'dashboard',
        component: Dashboard,
        exact: true,
        isProtected: true,
      },
      {
        id: 'users',
        path: 'users',
        component: UsersContainer,
        exact: true,
        isProtected: true,
      },
      {
        id: 'reports',
        path: 'reports',
        component: Reports,
        exact: true,
        isProtected: true,
      },
      {
        id: 'umm',
        path: 'umm',
        component: Umm,
        exact: true,
        isProtected: true,
      },
      {
        id: 'settings',
        path: 'settings',
        component: SettingsContainer,
        exact: true,
        isProtected: true,
      },
    ],
  },
  {
    id: 'uniqueLinkHandler',
    path: '/facilities/:facilityCode/links/:linkGuid',
    component: UniqueLinkHandler,
    isProtected: false,
  },
  {
    id: 'page404',
    path: '*',
    component: Page404,
    isProtected: false,
  },
];
