import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { TextBox } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import React from "react";

interface LabelInputProps extends FieldRenderProps {
  type?: string;
  id?: string;
  disabled?: boolean;
  maxLength?: number;
  inputMode?: string;
  onInputBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  loading?: boolean;
  laodingLabel?: string;
}

const LabelInput: React.FC<LabelInputProps> = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    label,
    id,
    valid,
    disabled,
    type = "text",
    value,
    maxLength,
    inputMode,
    loading,
    loadingLabel,
    onChange,
    onInputBlur,
    componentProps = {},
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <FieldWrapper>
      <Label
        className="k-form-label"
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}{" "}
        {loading && <span style={{ color: "#888" }}> ( {loadingLabel})</span>}
      </Label>
      <div className="k-form-field-wrap">
        <TextBox
          valid={valid}
          type={type}
          id={id}
          disabled={disabled || loading}
          value={componentProps.value}
          onChange={onChange}
          onBlur={(event) => {
            event.stopPropagation();
            if (onInputBlur) {
              onInputBlur(event);
            }
          }}
          autoFocus={false}
          fillMode={"outline"}
          maxLength={maxLength && maxLength}
        />
        {showValidationMessage && <Error>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default LabelInput;
