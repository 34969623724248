import { FacilityInfo } from 'services/auth/AuthInterface';
import { useUser } from '../hooks/useUser';
import { appRoutes, IRoute } from './appRoutes';
import { TProtectRouteProps } from './types/routing';
import { Route, Routes } from 'react-router-dom';

export enum Features {
  FEATURE_A = 'FEATURE_A',
}

export enum Permissions {
  // read = 'read',
  // write = 'write',
  // publish = 'publish',

  read = 'read',
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export type TPermission = 'read' | 'create' | 'update' | 'delete';

export type TPrivileges = {
  [key in Features]: any;
};

export interface IUser {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isAuthenticated: boolean;
  privileges: TPrivileges;
  facility?: FacilityInfo;
}

export interface IUserContext {
  user: IUser;
  saveUser: (newUser: IUser) => void;
  logout: () => void;
  isInitialized: boolean;
}

export interface IPermissionCache {
  [key: string]: TPermission[];
}
